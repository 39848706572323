import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from '@reach/router';

import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as NavBg } from '../../assets/images/nav_background.svg';
import { ReactComponent as Underline } from '../../assets/images/nav_underline.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/nav_menu.svg';
import MailingListModal from './MailingListModal';

const StyledNav = styled.div`
  color: ${props => props.theme.white};
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 1.1rem 1rem 0.7rem;
  z-index: 10;

  @media (max-width: ${props => props.theme.breakMedium}) {
    top: 0;
    bottom: initial;
    padding: 0 0 1rem;
  }
`;

const StyledContents = styled.div`
  position: relative;
  display: flex;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  background: ${props => props.theme.black};
  z-index: 2;

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: grid;
    grid-template:
      'menuItems menuItems'
      'subscribe subscribe'
      'logo menu'
      / 1fr auto;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
  }
`;

const StyledMenuItemsContainer = styled.div`
  display: flex;
  grid-area: menuItems;
  width: 100%;
`;

type StyledMenuItemsProps = {
  showMenu?: boolean;
  isRight?: boolean;
};

const StyledMenuItems = styled.div<StyledMenuItemsProps>`
  display: flex;
  grid-area: menuItems;

  @media (max-width: ${props => props.theme.breakMedium}) {
    flex-grow: 1;
    ${props =>
      props.showMenu
        ? css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          `
        : css`
            display: none;
          `};
    ${props =>
      props.isRight &&
      css`
        align-items: flex-end;
      `}
  }
`;

const StyledLogoLink = styled(Link)`
  grid-area: logo;
  margin-top: 0.2rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding-left: 1rem;
  }
`;

const StyledLogo = styled(Logo)`
  width: 13rem;
  fill: ${props => props.theme.white};

  @media (max-width: ${props => props.theme.breakMedium}) {
    width: 11rem;
  }
`;

const StyledMenu = styled.button`
  display: none;
  grid-area: menu;

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: flex;
    align-items: baseline;
    padding-right: 1rem;
    border: none;
    background: none;
    color: ${props => props.theme.white};
    font-size: 0.75rem;
    ${props => props.theme.customTextStyle};
  }
`;

const StyledMenuIcon = styled(MenuIcon)`
  margin-left: 0.5rem;
  height: 0.6rem;
`;

const StyledBG = styled(NavBg)`
  position: absolute;
  height: 100%;
  max-height: 60px;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;

  @media (max-width: ${props => props.theme.breakMedium}) {
    transform: scaleY(-1);
  }
`;

const StyledNavLink = styled(Link)`
  position: relative;
  margin-left: 2rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.025em;
  color: ${props => props.theme.white};
  ${props => props.theme.lightOnDarkTextStyle};

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin: 0 1rem 1rem;
    padding-bottom: 0.3rem;
  }
`;

const StyledSubscribe = styled.div`
  grid-area: subscribe;
  position: relative;
  display: ${props => (props.$isMobile ? 'none' : 'block')};
  margin-left: 2rem;

  input {
    padding-bottom: 0.3rem;
    background: none;
    border: none;
    font-family: ${props => props.theme.sansSerif};
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: -0.025em;
    color: ${props => props.theme.white};
    white-space: nowrap;
    vertical-align: top;
    ${props => props.theme.lightOnDarkTextStyle};
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: ${props => (props.$isMobile ? 'block' : 'none')};
    margin: 0 1rem 1rem;
  }
`;

const StyledUnderline = styled(Underline)`
  position: absolute;
  height: 2px;
  width: 100%;
  left: 0;
  bottom: 3px;
  fill: white;
`;

interface NavProps {
  menuItems: {
    name: string;
    link: string;
  }[];
  currPage?: string;
}

const Nav: React.FC<NavProps> = props => {
  const { menuItems, currPage } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const toggleModal = () => {
    setShowModal(!showModal);

    if (showMenu) {
      toggleMenu();
    }
  };

  const checkPageActive = (link: string): boolean => {
    const currPageRoot = `/${currPage?.split('/')[1]}`;
    return currPageRoot == link;
  };

  return (
    <StyledNav>
      <StyledContents>
        <StyledLogoLink to="/">
          <StyledLogo />
        </StyledLogoLink>
        <StyledMenu onClick={toggleMenu}>
          Menu
          <StyledMenuIcon />
        </StyledMenu>
        <StyledMenuItemsContainer>
          <StyledMenuItems showMenu={showMenu}>
            {menuItems
              .slice(0, Math.floor(menuItems.length / 2))
              .map((menuItem, idx) => (
                <StyledNavLink
                  key={idx}
                  to={menuItem.link}
                  onClick={toggleMenu}
                >
                  {menuItem.name}
                  {checkPageActive(menuItem.link) && <StyledUnderline />}
                </StyledNavLink>
              ))}
            <StyledSubscribe $isMobile>
              <input
                type="button"
                value="Subscribe to mailing list"
                onClick={toggleModal}
              />
              {showModal && <StyledUnderline />}
            </StyledSubscribe>
          </StyledMenuItems>
          <StyledMenuItems isRight={true} showMenu={showMenu}>
            {menuItems
              .slice(Math.floor(menuItems.length / 2), menuItems.length)
              .map((menuItem, idx) => (
                <StyledNavLink
                  key={idx}
                  to={menuItem.link}
                  onClick={toggleMenu}
                >
                  {menuItem.name}
                  {checkPageActive(menuItem.link) && <StyledUnderline />}
                </StyledNavLink>
              ))}
          </StyledMenuItems>
        </StyledMenuItemsContainer>
        <StyledSubscribe>
          <input
            type="button"
            value="Subscribe to mailing list"
            onClick={toggleModal}
          />
          {showModal && <StyledUnderline />}
        </StyledSubscribe>
      </StyledContents>
      <StyledBG />
      {showModal && <MailingListModal toggleModal={toggleModal} />}
    </StyledNav>
  );
};

export default Nav;
