import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Courtney Barnett';
    src: url(${require('../assets/fonts/courtneybarnett-webfont.woff2')}) format('woff2'),
         url(${require('../assets/fonts/courtneybarnett-webfont.woff')}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    font-size: 16px;

    @media (max-width: ${props => props.theme.breakMedium}) {
      font-size: 15px;
    }

    @media (max-width: ${props => props.theme.breakSmall}) {
      font-size: 14px;
    }
  }

  *::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    color: ${props => props.theme.black};
  }

  body {
    background: ${props => props.theme.black};
    font-family: ${props => props.theme.sansSerif};
  }

  input, textarea {
    user-select: text;
  }

  input[type="button"], input[type="submit"] {
    -webkit-appearance: none;
    cursor: pointer;
  }

  a {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
  }

  .grid-sizer {
    height: 0.1rem;
  }
`;

export default GlobalStyle;
