import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as Tape } from '../../assets/images/text_tape.svg';
import borderUrl from '../../assets/images/disco_embed_border.svg';
import buttonUrl from '../../assets/images/photo_border.svg';
import closeUrl from '../../assets/images/details_close.svg';

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  cursor: initial;
`;

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

const StyledTape = styled(Tape)`
  position: absolute;
  top: -1.4rem;
  left: 50%;
  width: 7rem;
  transform: translateX(-50%);
  stroke: ${props => props.theme.black};
  stroke-width: 14;
`;

const StyledFormWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 35rem;
`;

const StyledFormBorder = styled.div`
  ${props => props.theme.borderMask(borderUrl)};
`;

const StyledForm = styled.div`
  background: ${props => props.theme.white};
  padding: 3rem;
  ${props => props.theme.mask(borderUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding: 3rem 1rem 2rem;
  }
`;

const StyledText = css`
  ${props => props.theme.customTextStyle};
  color: ${props => props.theme.black};
  letter-spacing: 0;
  text-transform: initial;
`;

const StyledHeader = styled.div`
  display: grid;
  grid-template:
    'title close'
    'subtitle close';
  align-items: start;
`;

const StyledTitle = styled.h1`
  ${StyledText};
  font-weight: 400;
  font-size: 1.5rem;
`;

const StyledSubtitle = styled.h3`
  ${StyledText};
  font-weight: 400;
  font-size: 0.75rem;
  margin-bottom: 1.5rem;
`;

const StyledCloseButtonBorder = styled.div`
  display: inline-block;
  justify-self: end;
  margin-top: -0.5rem;
  ${props => props.theme.borderMask(closeUrl)};
`;

const StyledCloseButton = styled.input`
  background: ${props => props.theme.white};
  padding: 1.1rem 0.6rem 0.8rem 0.7rem;
  font-size: 1.25rem;
  border: none;
  cursor: pointer;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(closeUrl)};
`;

const StyledEmbed = styled.div`
  * {
    color: ${props => props.theme.black};
    ${props => props.theme.customTextStyle};
  }

  .mc-field-group {
    input {
      display: block;
      width: 100%;
      border: 2px solid ${props => props.theme.black};
      border-radius: 3px;
      padding: 0.5rem 0.5rem 0.3rem;
      margin-top: 0.2rem;
    }
  }

  .two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    margin: 1.2rem 0 1.5rem;

    @media (max-width: ${props => props.theme.breakMedium}) {
      gap: 1rem;
    }
  }

  #subscribe-wrapper {
    ${props => props.theme.borderMask(buttonUrl)};
    width: 10rem;
    text-align: center;

    @media (max-width: ${props => props.theme.breakMedium}) {
      margin: 0 auto;
    }
  }

  input[name='subscribe'] {
    border: 0;
    padding: 0.6rem 0 0.4rem;
    background: ${props => props.theme.black};
    color: ${props => props.theme.white};
    cursor: pointer;
  }

  .content__gdpr {
    margin-bottom: 1.5rem;

    fieldset {
      border: 0;
    }

    label {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 1rem;
      align-items: center;
    }

    input[name='gdpr[1]'] {
      width: 1.2rem;
      height: 1.2rem;
      margin: 0;
      padding: 0;
      cursor: pointer;
      appearance: none;

      &:checked {
        background: ${props => props.theme.black};
      }
    }

    span,
    a {
      font-size: 0.75rem;
      letter-spacing: 0;
      text-transform: initial;
    }

    a {
      line-height: 0.9;
      border-bottom: 1px solid ${props => props.theme.black};
    }
  }
`;

const embed = `<!-- Begin Mailchimp Signup Form -->
<div id="mc_embed_signup">
<form action="https://courtneybarnett.us2.list-manage.com/subscribe/post?u=ea263fe13b41825b4a92f4b16&amp;id=86e2e2dee0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
<div class="mc-field-group">
<label for="mce-EMAIL">Email: <span class="asterisk">*</span>
</label>
<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
</div>
<div class="two-columns">
<div class="mc-field-group">
<label for="mce-FNAME">First Name: </label>
<input type="text" value="" name="FNAME" class="" id="mce-FNAME">
</div>
<div class="mc-field-group">
<label for="mce-MMERGE3">Country: </label>
<input type="text" value="" name="MMERGE3" class="" id="mce-MMERGE3">
</div>
</div>
<div id="mergeRow-gdpr" class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group">
    <div class="content__gdpr">
        <fieldset class="mc_fieldset gdprRequired mc-field-group" name="interestgroup_field">
<label class="checkbox subfield" for="gdpr_1"><input type="checkbox" id="gdpr_1" name="gdpr[1]" value="Y" class="av-checkbox "><span>By signing up to the mailing list you agree to be contacted for marketing purposes inline with these <a href="/terms" target="_blank" rel="noopener noreferrer">terms and conditions</a>.</span> </label>
        </fieldset>
    </div>
</div>
<div id="mce-responses" class="clear">
<div class="response" id="mce-error-response" style="display:none"></div>
<div class="response" id="mce-success-response" style="display:none"></div>
</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_ea263fe13b41825b4a92f4b16_86e2e2dee0" tabindex="-1" value=""></div>
    <div id="subscribe-wrapper" class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[3]='MMERGE3';ftypes[3]='text';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
<!--End mc_embed_signup—>`;

interface MailingListModalProps {
  toggleModal: () => void;
}

const MailingListModal: React.FC<MailingListModalProps> = props => {
  const { toggleModal } = props;

  return (
    <StyledOverlay>
      <StyledBackdrop />
      <StyledFormWrapper>
        <StyledFormBorder>
          <StyledForm>
            <StyledHeader>
              <div>
                <StyledTitle>Subscribe to mailing list</StyledTitle>
                <StyledSubtitle>* indicates required</StyledSubtitle>
              </div>
              <StyledCloseButtonBorder>
                <StyledCloseButton
                  type="button"
                  value="x"
                  onClick={toggleModal}
                />
              </StyledCloseButtonBorder>
            </StyledHeader>
            <StyledEmbed dangerouslySetInnerHTML={{ __html: embed }} />
          </StyledForm>
        </StyledFormBorder>
        <StyledTape />
      </StyledFormWrapper>
    </StyledOverlay>
  );
};

export default MailingListModal;
